<template>
  <v-dialog v-model="spider_select_form" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("dispatch") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="selected_spider"
                item-text="name"
                item-value="uuid"
                :label="$t('status')"
                :items="spiders"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dispatchTask(true)">
          {{ $t("dispatch") }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="dispatchTask(false)">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    spider_select_form: false,
  },
  data: (vm) => ({
    selected_spider: "",
    spiders: [],
  }),
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    spider_select_form(newValue) {
      if (newValue == true) {
        console.log("get the spiders");
        this.getAvailableSpider();
      }
    },
  },
  methods: {
    getAvailableSpider() {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/spiders/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          status: 1,
          is_active: true,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          // console.log(response.data);
          currentObj.spiders = response.data.results;
          console.log("spdier " + currentObj.spiders.length);
          if (currentObj.spiders.length > 0) {
            // console.log("first "+JSON.stringify(currentObj.spiders[0]));
            currentObj.selected_spider = currentObj.spiders[0]["uuid"];
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    dispatchTask(confirm) {
      // emit close event
      console.log("selected_spider " + JSON.stringify(this.selected_spider));
      console.log(
        "confirm to dispatch " + confirm + " uuid" + this.selected_spider
      );

      this.$emit("onClose", confirm, this.selected_spider);
    },
  },
};
</script>