 <template>
  <v-row justify="center">
    <v-dialog v-model="pack_restore.confirm" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm</v-card-title>
        <v-card-text>
          <v-row colo> Restore pack ID:{{ pack_restore.pack_id }} </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="restore_confirm(true)">
            OK
          </v-btn>
          <v-btn color="green darken-1" text @click="restore_confirm(false)">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    pack_restore: {
      confirm: false,
      pack_id: null,
    },
  },
  data: (vm) => ({}),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    restore_confirm(confirm) {
        console.log("restore confirm "+ confirm)
      this.$emit("onRestore", confirm);
    },
  },
};
</script>