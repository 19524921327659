<template>
  <v-container id="pack-tables" fluid tag="section">
    <confirm-dialog
      :isShow="confirmData.isConfirm"
      :title="confirmData.confirm_title"
      :message="confirmData.confirm_msg"
      :selectItem="confirmData.selectItem"
      :selectAction="confirmData.selectAction"
      v-on:onClose="onClose"
      v-on:onConfirm="onConfirm"
    ></confirm-dialog>
    <!-- Restore confirm dialog -->
    <RestorePackDialog
      :pack_restore="pack_restore"
      v-on:onRestore="restore_confirm"
    ></RestorePackDialog>
    <SpiderSelectDialog
      :spider_select_form="select_dialog"
      v-on:onClose="dispatchTask"
    ></SpiderSelectDialog>
    <!-- confirm dialog -->
    <v-row justify="center">
      <v-dialog
        v-model="confirm_dialog.show"
        max-width="290"
        @click:outside="close"
      >
        <v-card>
          <v-card-title class="headline">Confirm</v-card-title>
          <v-card-text>
            <v-row> Start the pack ID:{{ confirm_dialog.id }} </v-row>
            <v-row> CDR: {{ confirm_dialog.cdr }} </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dispatchTask(true)">
              OK
            </v-btn>
            <v-btn color="green darken-1" text @click="dispatchTask(false)">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-dialog
        v-model="new_pack_form"
        max-width="600px"
        @click:outside="close"
      >
        <template v-slot:activator="{ on }">
          <v-col col="12">
            <v-btn
              color="secondary"
              dark
              v-on="on"
              class="ma-2"
              @click="createItem(true)"
            >
              <v-icon left>mdi-gavel</v-icon>
              {{ $t("pack-manual") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              dark
              v-on="on"
              @click="createItem(false)"
              class="ma-2"
            >
              <v-icon left>mdi-package-variant</v-icon>
              {{ $t("pack-create") }}
            </v-btn>
            <v-btn color="secondary" dark class="ma-2" @click="getApi">
              <v-icon left>mdi-cloud-refresh</v-icon>
              {{ $t("refresh") }}
            </v-btn>
          </v-col>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{
              this.pack_data.manual == true ? "Manual " : "New Pack"
            }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense v-if="this.pack_data.manual">
                <v-col cols="6">
                  <v-text-field
                    v-model="pack_data.cdr"
                    :label="$t('lbkey')"
                    v-if="this.pack_data.manual"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="pack_data.regno"
                    :label="$t('regno')"
                    v-if="this.pack_data.manual"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col cols="8">
                  <v-text-field v-model="pack_data.cdr" :label="$t('CDR')">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="pack_data.count" :label="$t('Count')">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="this.pack_data.manual">
                <v-col>
                  <v-radio-group v-model="pack_data.oc_type" row>
                    <v-radio
                      v-for="option in oc_option"
                      :key="option.text"
                      :label="option.text"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col v-if="pack_data.manual">
                  <v-radio-group v-model="pack_data.action_type_radio">
                    <v-radio
                      v-for="option in manual_radioOption"
                      :key="option.text"
                      :label="option.text"
                      :value="option.value"
                      @click="checkPackType"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-else>
                  <v-radio-group v-model="pack_data.action_type_radio">
                    <v-radio
                      v-for="option in radioOption"
                      :key="option.text"
                      :label="option.text"
                      :value="option.value"
                      @click="checkPackType"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="pack_data.system">
                    <v-radio
                      v-for="option in systemOption"
                      :key="option.text"
                      :label="option.text"
                      :value="option.value"
                      @click="checkSystem"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions v-if="action != 'view'">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-btn color="normal" dark class="ma-2" @click="confirmDeletePacks()">
          <v-icon left>mdi-gavel</v-icon>
          {{ $t("delete-select") }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- dispatch to spider -->
    <base-material-card
      icon="mdi-clipboard-text"
      title="Packs列表"
      class="px-5 py-3"
    >
      <!-- new pack -->

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        show-expand
        dense
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
        show-select
      >
        <template v-slot:expanded-item="{ item }">
          <td :colspan="9">
            <TaskPoolTable :pack_id="item.id"></TaskPoolTable>
          </td>
        </template>
        <template v-slot:header>
          <tr>
            <td></td>
            <td>
              <v-text-field
                v-model="filterItem.id"
                :label="$t('id')"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="filterItem.cdr"
                :label="$t('cdr')"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-text-field>
            </td>

            <td>
              <v-select
                v-model="filterItem.action_type"
                :label="$t('action_type')"
                :items="action_type"
                :item-text="(item) => $t(item.text)"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.system"
                :label="$t('system')"
                :items="system_type"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td>
              <v-select
                v-model="filterItem.status"
                :label="$t('status')"
                :items="status_items"
                outlined
                dense
                clearable
                @change="onChange"
              ></v-select>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>

        <template dense v-slot:item.action_type="{ item }">{{
          item.action_type | getText(action_type) | Translate
        }}</template>
        <template v-slot:item.system="{ item }">{{
          item.system | getText(system_type) | Translate
        }}</template>

        <template v-slot:item.status="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.status | getText(status_items) | Translate }}
            <template v-slot:input>
              <v-row align="baseline">
                <v-select
                  v-model="item.status"
                  :label="$t('status')"
                  :items="status_items"
                  large
                ></v-select>
              </v-row>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="confirmTask(item)"
                >mdi-application-export</v-icon
              >
            </template>
            <span>{{ $t("dispatch") }}</span>
          </v-tooltip>
          <!-- restore_dialog -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="restore_dialog(item)"
                >mdi-restart</v-icon
              >
            </template>
            <span>{{ $t("reset") }}</span>
          </v-tooltip>
          <!-- delete -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
                @click="confirmDeleteSchedule(item)"
                >mdi-delete-outline</v-icon
              >
            </template>
            <span>{{ $t("unpack") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="20"
      ></v-pagination>
    </base-material-card>
  </v-container>
</template>
<script>
import {
  systemOption,
  oc_option,
  radioOption,
  manual_radioOption,
  status_items,
  action_type,
  action_type_enum,
  system_type_enum,
} from "@/definition.js";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import SpiderSelectDialog from "../dialogs/SpiderSelectDialog";
import RestorePackDialog from "../dialogs/RestorePackDialog";
import { mapState } from "vuex";
import TaskPoolTable from "./TaskPoolTable";
export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    confirm_dialog: {
      show: false,
      id: null,
      cdr: null,
      item: null,
    },
    pack_data: {
      cdr: "H_03",
      action_type_radio: action_type_enum.List,
      system: system_type_enum.EPLand,
      manual: false,
      regno: null,
      count: 45,
      oc_type: 0,
    },
    selected: [],
    new_pack_form: false,
    select_dialog: false,
    radioOption: radioOption,

    manual_radioOption: manual_radioOption,

    systemOption: systemOption,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    editedItem: {
      city: "",
      district: "",
      region: "",
    },
    defaultItem: {
      city: "",
      district: "",
      region: "",
    },
    filterItem: {
      id: null,
      city: null,
      district: null,
      region: null,
      lbkey: null,
      action: null,
      status: null,
      create_time: {},
    },
    editedIndex: -1,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
        width: "5px",
      },
      {
        sortable: false,
        text: vm.$i18n.t("cdr"),
        value: "cdr",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action_type"),
        value: "action_type",
      },
      {
        sortable: false,
        text: vm.$i18n.t("system"),
        value: "system",
      },
      {
        sortable: false,
        text: vm.$i18n.t("status"),
        value: "status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("update_time"),
        value: "update_time",
      },
      {
        sortable: false,
        text: vm.$i18n.t("account"),
        value: "account.hn",
      },
      {
        sortable: false,
        text: vm.$i18n.t("spider"),
        value: "spider.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%",
      },
    ],

    status_items: status_items,

    action_type: action_type,
    system_type: systemOption,
    spiders: [],
    // restore_dialog pack
    pack_restore: {
      confirm: false,
      pack_id: null,
    },
    oc_option: oc_option,
    confirmData: {
      isConfirm: false,
      confirm_title: "啟動排程",
      confirm_msg: "",
      selectItem: null,
      selectAction: 0,
    },
  }),
  components: {
    TaskPoolTable,
    SpiderSelectDialog,
    RestorePackDialog,
    ConfirmDialog,
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("hnaccount");
    },
    query_quota() {
      if (this.editedItem != null) {
        return this.getQuota();
      }
      return 0;
    },
  },
  methods: {
    getRegionDisplay(item) {
      return item.name + "(" + item.code + ")";
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/packs/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: this.page,
          page_size: this.itemsPerPage,
        },
      };
      if (!this.$common_util.isEmpty(this.filterItem.action_type)) {
        config.params["action_type"] = this.filterItem.action_type;
      }
      if (!this.$common_util.isEmpty(this.filterItem.system)) {
        config.params["system"] = this.filterItem.system;
      }
      if (!this.$common_util.isEmpty(this.filterItem.status)) {
        config.params["status"] = this.filterItem.status;
      } else {
        config.params["status__lte"] = "3";
      }
      config.params["order_by"] = "-id";
      // console.log("para " + JSON.stringify(config.params));
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    postApi() {
      console.log("create new pack");
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/packs/pack/";
      let jsonData = {
        cdr: this.pack_data.cdr,
        action_type: this.pack_data.action_type_radio,
        system: this.pack_data.system,
        manual: this.pack_data.manual,
        count: this.pack_data.count,
        oc_type: this.pack_data.oc_type,
      };
      if (this.pack_data.regno != null) {
        jsonData["regno"] = this.pack_data.regno;
      }
      console.log(jsonData);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log(jsonData);
      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          let result = JSON.parse(JSON.stringify(response.data));

          if (result["status"] == 0) {
            alert(result["msg"]);
          } else {
            currentObj.getApi();
          }
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
    },
    createItem(manual) {
      this.action = "create";

      if (manual == true && this.pack_data.action_type_radio == 0) {
        this.pack_data.action_type_radio = 1;
      }

      this.pack_data.manual = manual;
    },
    resetPackData() {
      // this.pack_data.manual = false;
      // this.pack_data.regno = null;
      // this.pack_data.cdr = null;
    },
    close() {
      console.log("close me.");
      this.new_pack_form = false;
      this.select_dialog = false;
      this.confirm_dialog.show = false;
      this.resetPackData();
    },
    save() {
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        this.patchApi(this.editedIndex);
        this.close();
      } else {
        this.postApi();
        this.close();
      }
      this.resetPackData();
    },
    onChange() {
      this.page = 1;
      this.getApi();
    },
    dispatchTask(isStart, uuid) {
      let item = this.confirm_dialog.item;

      if (item != null && isStart == true) {
        console.log("Start the task id " + item.id + " cdr:" + item.cdr);

        const currentObj = this;
        const url =
          process.env.VUE_APP_SERVER_URL + "/backend/packs/startPack/";
        let jsonData = {
          id: item.id,
          cdr: item.cdr,
        };
        if (item.spider) {
          jsonData["uuid"] = item.spider.uuid;
        }

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + this.token,
          },
        };
        console.log(jsonData);
        this.axios
          .post(url, jsonData, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.getApi();
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }
      this.select_dialog = false;

      this.confirm_dialog.show = false;
      this.confirm_dialog.item = null;
      this.confirm_dialog.id = null;
      this.confirm_dialog.cdr = null;
    },
    confirmTask(item) {
      this.confirm_dialog.item = item;
      this.confirm_dialog.id = item.id;
      this.confirm_dialog.cdr = item.cdr;
      console.log(
        "process.env.VUE_APP_DISTRIBUTED " + process.env.VUE_APP_DISTRIBUTED
      );
      if (
        process.env.VUE_APP_DISTRIBUTED == "true" ||
        process.env.VUE_APP_DISTRIBUTED == true
      ) {
        this.select_dialog = true;
      } else {
        console.log("local");
        this.confirm_dialog.show = true;
      }
    },

    updateStatus(item) {
      console.log("save " + item.id + " name" + item.status);
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/packs/" + item.id + "/";
      let jsonData = {
        status: item.status,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      console.log("json " + jsonData);
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    restore_dialog(item) {
      console.log("Restore the pack " + item.id);
      this.pack_restore.pack_id = item.id;
      this.pack_restore.confirm = true;
    },
    restore_confirm(confirm) {
      console.log("Restore " + confirm);
      if (confirm && this.pack_restore.pack_id != null) {
        const currentObj = this;
        const url =
          process.env.VUE_APP_SERVER_URL + "/backend/packs/restorePack/";
        let jsonData = {
          id: this.pack_restore.pack_id,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + this.token,
          },
        };
        console.log(jsonData);
        this.axios
          .post(url, jsonData, config)
          .then(function (response) {
            console.log(response.data);
            currentObj.getApi();
          })
          .catch(function (error) {
            console.log(error);
            console.log(error.response);
          });
      }

      this.pack_restore.pack_id = null;
      this.pack_restore.confirm = false;
    },
    checkSystem() {
      if (this.pack_data.system == 1 || this.pack_data.system == 2) {
        this.pack_data.action_type_radio = 1;
      } else if (this.pack_data.system == 3) {
        this.pack_data.action_type_radio = 2;
      }
    },

    checkPackType() {
      if (this.pack_data.action_type_radio == action_type_enum.Mixed) {
        this.pack_data.system = system_type_enum.QuantaSoft;
        this.pack_data.count = 45;
      } else if (this.pack_data.action_type_radio == action_type_enum.List) {
        this.pack_data.system = system_type_enum.EPLand;
        this.pack_data.count = 45;
      } else if (
        this.pack_data.action_type_radio == action_type_enum.Transcript
      ) {
        this.pack_data.system = system_type_enum.Gaias;
        this.pack_data.count = 5;
      }
    },
    confirmDeleteSchedule(item) {
      this.confirmData.selectItem = item;
      this.confirmData.selectAction = 999;
      this.confirmData.confirm_msg = `刪除任務: ${item.id}`;
      this.confirmData.isConfirm = true;
    },
    getSelectArray() {
      let ids_array = [];
      this.selected.forEach((item) => {
        ids_array.push(item.id);
      });
      return ids_array;
    },
    confirmDeletePacks() {
      if (this.selected.length == 0) {
        // show alert dialog
        alert("請選擇要刪除的pack ID.");
        return;
      }
      // this.confirmData.selectItem = null;
      this.confirmData.confirm_title = "Pack";
      this.confirmData.selectAction = 123;
      this.confirmData.confirm_msg = `清除pack ID: ${this.getSelectArray()}`;
      this.confirmData.isConfirm = true;
    },
    onClose() {
      this.confirmData.isConfirm = false;
      this.confirmData.confirm_msg = "";
      this.confirmData.selectAction = 0;
      this.confirmData.selectItem = null;
    },
    onConfirm(data) {
      let _action = data.action;
      let _item = data.item;
      console.log(`On Confirm:Action ${_action}`);
      switch (_action) {
        case 123:
          console.log("clear pack");
          this.unPackSelected();
          break;
        case 999:
          console.log(`Remove the pack ${_item.id}`);
          this.unPack(_item);
          break;
      }

      this.onClose();
    },
    unPackSelected() {
      let ids = this.getSelectArray();

      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/packs/unpack_arrray/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      let jsonData = {
        ids: ids.join(","),
      };

      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.page = 1;
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
          currentObj.selected = [];
        });
    },
    unPack(item) {
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/backend/packs/unpack/`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      let jsonData = {
        id: item.id,
      };

      this.axios
        .post(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.page = 1;
          // currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
};
</script>
